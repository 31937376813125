export const userInputs = [
    {
        id: 'userName',
        label: "Username",
        type: "text",
        placeholder: "john_doe",
    },
    {
        id: "email",
        label: "Email",
        type: "mail",
        placeholder: "john_doe@gmail.com",
    },
    {
        id: "password",
        label: "Password",
        type: "password",
    },

];